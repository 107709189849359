import './App.css';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AppState from './AppState';
import InvestmentAmount from './InvestmentAmount';
import HouseholdAum from './HouseholdAum';
import ModelType from './ModelType';
import CurrentAdvisorAum from './CurrentAdvisorAum';
import PlatformFeeTable from './PlatformFeeTable';
import InvestmentManagementFee from './InvestmentManagementFee';
import AdvisoryFee from './AdvisoryFee';
import TotalFees from './TotalFees';
import FeeDisclaimer from './FeeDisclaimer';
import FooterDisclaimer from './FooterDisclaimer';
import Instructions from './Instructions';
import SaveAsPdf from './SaveAsPdf';
import PdfHeaderSummary from './PdfHeaderSummary';

function App() {
  const appState = AppState(global.location.search);
  const state = appState.getState();

  return (
    <div id="App" className="App">
      <h1>
        Freedom Advisors Fee Calculator
      </h1>
      <Form>
        {!state.showCalculations
          ? <Instructions
              parsedInvestmentAmount={state.parsedInvestmentAmount}
              modelType={state.modelType}
              advisorAum={state.advisorAum}
              />
          : null
        }
        {state.showCalculations
          ? <FeeDisclaimer parsedHouseholdAum={state.parsedHouseholdAum} />
          : null
        }
        <PdfHeaderSummary
          parsedInvestmentAmount={state.parsedInvestmentAmount}
          householdAum={state.householdAum}
          modelType={state.modelType}
        />
        <div className="hide-while-printing">
          <Row xs={1} md={2}>
            <Col><InvestmentAmount val={state.investmentAmount} setVal={appState.updateInvestmentAmount}/></Col>
            <Col><HouseholdAum val={state.householdAum} setVal={appState.updateHouseholdAum} /></Col>
          </Row>
          <Row xs={1} md={2}>
            <Col><ModelType val={state.modelType} setVal={appState.updateModelType}/></Col>
            <Col><CurrentAdvisorAum val={state.advisorAum} setVal={appState.updateAdvisorAum}/></Col>
          </Row>
        </div>
        {state.exceededInvestmentAmount
          ? <div className="PlatformFeeTable">For investments over $5M negotiate with your consultant</div>
          : null
        }
        {state.showCalculations
          ? <PlatformFeeTable
              feeSchedule={state.feeSchedule}
              calculation={state.feeCalculation}
              />
          : null
        }
        {state.showCalculations
          ? <InvestmentManagementFee
              subAdvisorShare={state.subAdvisorShare}
              updateSubAdvisorShare={appState.updateSubAdvisorShare}
              subAdvisorRate={state.subAdvisorRate}
              updateSubAdvisorRate={appState.updateSubAdvisorRate}
              parsedInvestmentAmount={state.parsedInvestmentAmount}
              investmentManagementFee={state.investmentManagementFee}
              />
          : null
        }
        {state.showCalculations
          ? <AdvisoryFee
              val={state.advisorRate}
              setVal={appState.updateAdvisorRate}
              advisorFee={state.advisorFee}
              />
          : null
        }
        {state.showCalculations
          ? <TotalFees
              parsedInvestmentAmount={state.parsedInvestmentAmount}
              feeCalculationTotals={state.feeCalculation.totals}
              parsedadvisorRate={state.parsedadvisorRate}
              advisorFee={state.advisorFee}
              investmentManagementRate={state.investmentManagementRate}
              investmentManagementFee={state.investmentManagementFee}
              />
          : null
        }
        {state.showCalculations
          ? <SaveAsPdf />
          : null
        }
        {state.showCalculations
          ? <FooterDisclaimer />
          : null
        }
      </Form>
    </div>
  );
}

export default App;
