import './App.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {useState} from 'react';

const numberFormatter = Intl.NumberFormat('en-us');

function HouseholdAum(props) {
  function onChange(event) {
    const val = event.target.value;
    const strippedVal = val.replace(/[^0-9.]/g, '');
    props.setVal(strippedVal);
  }
  function onFocus() {
    setFocus(true);
  }
  function onBlur() {
    setFocus(false);
  }

  const [focused, setFocus] = useState(false);

  const value = focused
    ? props.val
    : numberFormatter.format(props.val);

  return (
    <div className="HouseholdAum">
      <Form.Label htmlFor="householdAum">Current Freedom AUM for Household</Form.Label>
      <InputGroup className="mb-3">
        <InputGroup.Text>$</InputGroup.Text>
        <Form.Control id="householdAum" placeholder="" value={value} onChange={onChange} onFocus={onFocus} onBlur={onBlur}/>
      </InputGroup>
    </div>
  );
}

export default HouseholdAum;
