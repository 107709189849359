import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import {useState} from 'react';

const moneyFormatter = Intl.NumberFormat('en-us', {style: 'currency', currency: 'USD'});
const percentageFormatter = Intl.NumberFormat('en-us', {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 4});

function AdvisoryFee(props) {
  const [focused, setFocus] = useState(false);

  function onChange(event) {
    const val = event.target.value;
    let strippedVal = val.replace(/[^0-9.]/g, '');
    props.setVal(strippedVal);
  }

  return (
    <div className="AdvisoryFee">
      <h3>Advisory Fee</h3>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th className="text-end">Annual Advisory Fee</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Annual Advisory Rate</td>
            <td className="text-end">
              <Form.Control
                  className="hide-while-printing"
                  value={focused ? props.val : percentageFormatter.format(props.val/100)}
                  onChange={onChange}
                  onFocus={setFocus.bind(null, true)}
                  onBlur={setFocus.bind(null, false)} />
              <span className="show-while-printing">{percentageFormatter.format(props.val/100)}</span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Estimated Annual Advisory Fee</th>
            <th className="text-end">{moneyFormatter.format(props.advisorFee)}</th>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
}

export default AdvisoryFee;
