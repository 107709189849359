import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import {useState} from 'react';

const moneyFormatter = Intl.NumberFormat('en-us', {style: 'currency', currency: 'USD'});
const percentageFormatter = Intl.NumberFormat('en-us', {style: 'percent', maximumFractionDigits: 4});

function InvestmentManagementFee(props) {
  const [portfolioShareFocused, setFocusPortfolioShare] = useState(false);
  const [subAdvisorRateFocused, setFocusSubAdvisorRate] = useState(false);

  function onChangePortfolioShare(event) {
    const val = event.target.value;
    const strippedVal = val.replace(/[^0-9.]/g, '');
    props.updateSubAdvisorShare(strippedVal);
  }

  function onChangeSubAdvisorRate(event) {
    const val = event.target.value;
    const strippedVal = val.replace(/[^0-9.]/g, '');
    props.updateSubAdvisorRate(strippedVal);
  }

  return (
    <div className="InvestmentManagementFee">
      <h3>Investment Management Fee</h3>
      <p>
        Type in the estimated initial share (%) of the portfolio that will be allotted to sub-advisers, excluding Freedom, and not pooled vehicles.
        The "Estimated Sub-Adviser Fee Annual Rate" defaults to 0.15% because most sub-advisers charge 0.15%.
        If one or more sub-advisers selected have a different fee rate, adjust accordingly.
        Freedom does not charge an Investment Management Fee on Freedom Portfolios.
      </p>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th className="text-end">Annual Investment Management Fee</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Share of Portfolio Allocated to Sub-Adviser Strategies</td>
            <td className="text-end">
              <Form.Control
                  className="hide-while-printing"
                  value={portfolioShareFocused ? props.subAdvisorShare : percentageFormatter.format(props.subAdvisorShare/100)}
                  onChange={onChangePortfolioShare}
                  onFocus={setFocusPortfolioShare.bind(null, true)}
                  onBlur={setFocusPortfolioShare.bind(null, false)} />
              <span className="show-while-printing">{percentageFormatter.format(props.subAdvisorShare/100)}</span>
            </td>
          </tr>
          <tr>
            <td>Estimated Sub-Adviser Fee Annual Rate</td>
            <td className="text-end">
              <Form.Control
                  className="hide-while-printing"
                  value={subAdvisorRateFocused ? props.subAdvisorRate : percentageFormatter.format(props.subAdvisorRate/100)}
                  onChange={onChangeSubAdvisorRate}
                  onFocus={setFocusSubAdvisorRate.bind(null, true)}
                  onBlur={setFocusSubAdvisorRate.bind(null, false)} />
              <span className="show-while-printing">{percentageFormatter.format(props.subAdvisorRate/100)}</span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Estimated Annual Investment Management Fee</th>
            <th className="text-end">{moneyFormatter.format(props.investmentManagementFee)}</th>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
}

export default InvestmentManagementFee;
