import './App.css';
import Table from 'react-bootstrap/Table';

const moneyFormatter = Intl.NumberFormat('en-us', {style: 'currency', currency: 'USD'}).format;
const percentageFormatter = Intl.NumberFormat('en-us', {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format;

function PlatformFeeTable(props) {
  return (
    <div className="PlatformFeeTable">
      <h3>Freedom Platform Fee</h3>
      <Table striped>
        <thead>
          <tr>
            <th>Household Assets Under Management</th>
            <th className="text-end">Investment Amount Assigned to Tiers</th>
            <th className="text-end">%</th>
            <th className="text-end">$</th>
          </tr>
          <tr>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>&lt;$250,000</td>
            <td className="text-end">{moneyFormatter(props.calculation.amountInTiers[0])}</td>
            <td className="text-end">{percentageFormatter(props.feeSchedule[0])}</td>
            <td className="text-end">{moneyFormatter(props.calculation.platformFeesByTier[0])}</td>
          </tr>
          <tr>
            <td>Next $250,000</td>
            <td className="text-end">{moneyFormatter(props.calculation.amountInTiers[1])}</td>
            <td className="text-end">{percentageFormatter(props.feeSchedule[1])}</td>
            <td className="text-end">{moneyFormatter(props.calculation.platformFeesByTier[1])}</td>
          </tr>
          <tr>
            <td>Next $1,500,000</td>
            <td className="text-end">{moneyFormatter(props.calculation.amountInTiers[2])}</td>
            <td className="text-end">{percentageFormatter(props.feeSchedule[2])}</td>
            <td className="text-end">{moneyFormatter(props.calculation.platformFeesByTier[2])}</td>
          </tr>
          <tr>
            <td>Next $3,000,000</td>
            <td className="text-end">{moneyFormatter(props.calculation.amountInTiers[3])}</td>
            <td className="text-end">{percentageFormatter(props.feeSchedule[3])}</td>
            <td className="text-end">{moneyFormatter(props.calculation.platformFeesByTier[3])}</td>
          </tr>
          <tr>
            <td>$5,000,000+</td>
            <td colSpan={3} className="text-center">For $5M+ negotiate with your Consultant</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={2}>Estimated Total Annual Platform Fee</th>
            <th className="text-end">{percentageFormatter(props.calculation.totals.percentage)}</th>
            <th className="text-end">{moneyFormatter(props.calculation.totals.fees)}</th>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
}

export default PlatformFeeTable;
