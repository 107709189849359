const aumBreakpoints = [250000, 250000, 1500000, 3000000];

function GetAmountInTiersForInvestment(breakpoints, investment) {
  let amountRemaining = investment;
  let amountInTiers = [];
  for (let tier = 0; tier < breakpoints.length; tier++) {
    let amountInTier = amountRemaining < breakpoints[tier]
      ? amountRemaining
      : breakpoints[tier];
    amountInTiers.push(amountInTier);
    amountRemaining -= amountInTier;
  }
  if (amountRemaining !== 0) {
    throw new Error('no tier found for remaining aum ' + amountRemaining);
  }
  return amountInTiers;
}

function GetFeesByTier(feeSchedule, amountInTiers) {
  if (feeSchedule.length !== amountInTiers.length)
    throw new Error('unexpected fee schedule');

  let feesByTier = [];
  for (let tier = 0; tier < feeSchedule.length; tier++) {
    feesByTier.push(feeSchedule[tier] * amountInTiers[tier]);
  }

  return feesByTier;
}

function GetTotals(investmentAmount, feesByTier) {
  const totalFees = feesByTier.reduce((a,b) => a + b, 0);
  const totalPercentage = totalFees/investmentAmount;
  return {
    fees: totalFees,
    percentage: totalPercentage,
  }
}

function PlatformFeeCalculation(feeSchedule, investmentAmount, householdAum) {
  if (feeSchedule.length !== 4)
    throw new Error('unexpected fee schedule');

  if (!(investmentAmount >= 0))
    throw new Error('investmentAmount must be positive');

  if (!(householdAum >= 0))
    throw new Error('householdAum must be positive');

  const householdAmountInTiers = GetAmountInTiersForInvestment(aumBreakpoints, householdAum);

  const newAumBreakpoints = aumBreakpoints.map((breakpoint, index) => {
    return breakpoint-householdAmountInTiers[index];
  })

  const amountInTiers = GetAmountInTiersForInvestment(newAumBreakpoints, investmentAmount);
  const platformFeesByTier = GetFeesByTier(feeSchedule, amountInTiers);
  const totals = GetTotals(investmentAmount, platformFeesByTier);
  return {
    amountInTiers: amountInTiers,
    platformFeesByTier: platformFeesByTier,
    totals: totals,
  };
}

export default PlatformFeeCalculation;
