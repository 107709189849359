import Table from 'react-bootstrap/Table';

const moneyFormatter = Intl.NumberFormat('en-us', {style: 'currency', currency: 'USD'}).format;
const percentageFormatter = (v) => Intl.NumberFormat('en-us', {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(v);

function TotalFees(props) {
  const totalFees = props.feeCalculationTotals.fees + props.investmentManagementFee + props.advisorFee;
  const totalRate = totalFees / props.parsedInvestmentAmount;
  return (
    <div className="TotalFees">
      <h3>Estimated Totals</h3>
      <Table striped>
        <thead>
          <tr>
            <th></th>
            <th className="text-end">Rate</th>
            <th className="text-end">Fees</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Annual Freedom Platform Fee</td>
            <td className="text-end">{percentageFormatter(props.feeCalculationTotals.percentage)}</td>
            <td className="text-end">{moneyFormatter(props.feeCalculationTotals.fees)}</td>
          </tr>
          <tr>
            <td>Annual Investment Management Fee</td>
            <td className="text-end">{percentageFormatter(props.investmentManagementRate)}</td>
            <td className="text-end">{moneyFormatter(props.investmentManagementFee)}</td>
          </tr>
          <tr>
            <td>Annual Advisory Fee</td>
            <td className="text-end">{percentageFormatter(props.parsedadvisorRate)}</td>
            <td className="text-end">{moneyFormatter(props.advisorFee)}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Estimated Total Annual Fee</th>
            <th className="text-end">{percentageFormatter(totalRate)}</th>
            <th className="text-end">{moneyFormatter(totalFees)}</th>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
}

export default TotalFees;
