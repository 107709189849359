const feeSchedule = {
  "UMA": {
    "0": [0.0083, 0.0074, 0.0070, 0.0050],
    "2.5": [0.0079, 0.0073, 0.0066, 0.0048],
    "5": [0.0078, 0.0072, 0.0064, 0.0047],
    "10": [0.0076, 0.0070, 0.0062, 0.0045],
    "20": [0.0075, 0.0065, 0.0060, 0.0043],
    "30": [0.0073, 0.0063, 0.0055, 0.0042],
    "40": [0.0070, 0.0060, 0.0050, 0.0040],
    "50": [0.0065, 0.0050, 0.0045, 0.0035],
    "100": [0.0050, 0.0040, 0.0030, 0.0025],
    "AFP": [0.0091, 0.0085, 0.0068, 0.0050],
  },
  "Fund": {
    "0": [0.0040, 0.0038, 0.0036, 0.0034],
    "2.5": [0.0038, 0.0036, 0.0034, 0.0032],
    "5": [0.0036, 0.0034, 0.0032, 0.0030],
    "10": [0.0034, 0.0032, 0.0030, 0.0028],
    "20": [0.0032, 0.0030, 0.0028, 0.0026],
    "30": [0.0032, 0.0030, 0.0028, 0.0026],
    "40": [0.0032, 0.0030, 0.0028, 0.0026],
    "50": [0.0030, 0.0028, 0.0026, 0.0024],
    "100": [0.0028, 0.0026, 0.0024, 0.0022],
    "AFP": [0.0049, 0.0047, 0.0036, 0.0033],
  }
}

function PlatformFeeSchedule(modelType, advisorAum) {
  const validModelTypes = ["UMA", "Fund"];
  const validAdvisorAum = ["0","2.5","5","10","20","30","40","50","100","AFP",]
  if (validModelTypes.indexOf(modelType) === -1)
    throw new Error('invalid model type');
  if (validAdvisorAum.indexOf(advisorAum) === -1)
    throw new Error('invalid advisor aum');
  return feeSchedule[modelType][advisorAum];
}

export default PlatformFeeSchedule;
