const moneyFormatter = Intl.NumberFormat('en-us', {style: 'currency', currency: 'USD'}).format;

function PdfHeaderSummary(props) {
  return (
    <div className="show-while-printing">
      <div><strong>Calculation Date:</strong> {new Date().toLocaleDateString()}</div>
      <div><strong>Investment Amount:</strong> {moneyFormatter(props.parsedInvestmentAmount)}</div>
      {props.householdAum === ""
        ? null
        : <div><strong>Current Freedom AUM for Household:</strong> {moneyFormatter(props.householdAum)}</div>
      }
      <div><strong>Model Type:</strong> {props.modelType}</div>
    </div>
  );
}

export default PdfHeaderSummary;
