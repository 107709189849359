function requiredFieldsToText(fields) {
  if (fields.length === 1)
    return fields[0];
  if (fields.length === 2)
    return fields[0] + ' and ' + fields[1];
  return fields.slice(0, -1).join(', ') + ', and ' + fields.slice(-1)[0];
}

function Instructions(props) {
  let requiredFields = [];
  if (!parseFloat(props.parsedInvestmentAmount) > 0)
    requiredFields.push('Investment Amount');
  if (props.modelType === '')
    requiredFields.push('Model Type');
  if (props.advisorAum === '')
    requiredFields.push('Current Advisor AUM with Freedom');
  const requiredFieldsAsText = requiredFieldsToText(requiredFields);
  return (
    <div className='Instructions'>
      <p>
        Fill out {requiredFieldsAsText} to calculate fees.
      </p>
    </div>
  );
}

export default Instructions;
