function FeeDisclaimer(props) {
  return (
    <div className={"FeeDisclaimer " + (props.parsedHouseholdAum === 0 ? "hide-while-printing" : "")}>
      <p>
        The fees displayed reflect the Investment Amount
        and do not include fees for the total entered in Current Freedom AUM for Household.
      </p>
    </div>
  );
}

export default FeeDisclaimer;
