import './App.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

function CurrentAdvisorAum(props) {
  function onChange(event) {
    props.setVal(event.target.value);
  }

  return (
    <div className="CurrentAdvisorAum">
      <Form.Label htmlFor="currentAdvisorAum">Current Advisor AUM with Freedom&nbsp;<strong className="text-danger">*</strong></Form.Label>
      <InputGroup className="mb-3">
        <Form.Select id="currentAdvisorAum" value={props.val} onChange={onChange}>
          <option value=""></option>
          <option value="100">$100.0M+</option>
          <option value="50">$50.0M-$99.9M</option>
          <option value="40">$40.0M-$49.9M</option>
          <option value="30">$30.0M-$39.9M</option>
          <option value="20">$20.0M-$29.9M</option>
          <option value="10">$10.0M-$19.9M</option>
          <option value="5">$5.0M-$9.9M</option>
          <option value="2.5">$2.5M-$4.9M</option>
          <option value="0">&lt;$2.5M</option>
          <option value="AFP">AFP Select</option>
        </Form.Select>
      </InputGroup>
      <div className="FeeTierInstructions">
        Your current fee tier can be found in Freedom Advisors>Contacts>Non-Customer Contacts>[click on your name]>Detail & Activities>User Info or by asking Freedom Advisors.
        Your fee tier will be reviewed and reset annually on January 1st,
        however, if you substantially surpass the top of your current tier (25%+) and contact us directly,
        your fee tier may be reset prior to the next annual evaluation.
        Do not select in this dropdown an AUM fee tier other than the one in which you are officially assigned at this time.
      </div>
    </div>
  );
}

export default CurrentAdvisorAum;
