import {useState} from 'react';
import PlatformFeeSchedule from './PlatformFeeSchedule';
import PlatformFeeCalculation from './PlatformFeeCalculation';

const urlParams = {
  investmentAmount: 'investment',
  householdAum: 'household',
  modelType: 'model',
  advisorAum: 'advisorAum',
  subAdvisorShare: 'subAdvisorShare',
  subAdvisorRate: 'subAdvisorRate',
  advisorRate: 'advisorRate',
};

const stateDefaults = {
  investmentAmount: '',
  householdAum: '',
  modelType: '',
  advisorAum: '',
  subAdvisorShare: '100',
  subAdvisorRate: '.15',
  advisorRate: '1.00',
}

const validModelTypes = ['', 'UMA', 'Fund'];
const validAdvisorAum = ['','0','2.5','5','10','20','30','40','50','100','AFP'];

function limitStateValues(state) {
  function validNumbers(value) {
    const cleanedValue = value.replace(/[^0-9.]/g, '');
    if (cleanedValue === '.')
      return '0.'
    if (isNaN(parseFloat(cleanedValue)))
      return '';
    return cleanedValue;
  }

  state.investmentAmount = validNumbers(state.investmentAmount);
  state.householdAum = validNumbers(state.householdAum);
  state.subAdvisorShare = validNumbers(state.subAdvisorShare);
  state.subAdvisorRate = validNumbers(state.subAdvisorRate);
  state.advisorRate = validNumbers(state.advisorRate);
  if (parseFloat(state.advisorRate) > 1.5)
    state.advisorRate = '1.5';
  if (parseFloat(state.subAdvisorShare) > 100)
    state.subAdvisorShare = '100';
  if (validModelTypes.indexOf(state.modelType) === -1)
    state.modelType = stateDefaults.modelType;
  if (validAdvisorAum.indexOf(state.advisorAum) === -1)
    state.advisorAum = stateDefaults.advisorAum;
  return state;
}

function AppState(locationSearch) {
  const searchParams = new URLSearchParams(locationSearch);

  function initialState() {
    let initialState = {};
    for (let key in urlParams) {
      initialState[key] = searchParams.get(urlParams[key]) ?? stateDefaults[key]
    }

    return limitStateValues(initialState);
  }

  const [state, setState] = useState(initialState())
  updateUrlFromState(state);

  function updateUrlFromState(newState) {
    for (let key in newState) {
      const urlKey = urlParams[key];
      const value = newState[key];
      searchParams.set(urlKey, value);
      if (value === null || value === stateDefaults[key])
        searchParams.delete(urlKey);
    }
    global.history.replaceState(null, null, "?" + searchParams.toString());
  }

  function updateInvestmentAmount(value) {
    var newState = limitStateValues({...state, investmentAmount: value});
    setState(newState);
    updateUrlFromState(newState);
  }

  function updateHouseholdAum(value) {
    var newState = limitStateValues({...state, householdAum: value});
    setState(newState);
    updateUrlFromState(newState);
  }

  function updateModelType(value) {
    var newState = limitStateValues({...state, modelType: value});
    setState(newState);
    updateUrlFromState(newState);
  }

  function updateAdvisorAum(value) {
    var newState = limitStateValues({...state, advisorAum: value});
    setState(newState);
    updateUrlFromState(newState);
  }

  function updateSubAdvisorShare(value) {
    var newState = limitStateValues({...state, subAdvisorShare: value});
    setState(newState);
    updateUrlFromState(newState);
  }

  function updateSubAdvisorRate(value) {
    var newState = limitStateValues({...state, subAdvisorRate: value});
    setState(newState);
    updateUrlFromState(newState);
  }

  function updateAdvisorRate(value) {
    var newState = limitStateValues({...state, advisorRate: value});
    setState(newState);
    updateUrlFromState(newState);
  }

  function getState() {
    const parsedHouseholdAum = state.householdAum === ''
      ? 0
      : parseFloat(state.householdAum);
    const parsedInvestmentAmount = state.investmentAmount === ''
      ? 0
      : parseFloat(state.investmentAmount);
    const parsedSubAdvisorRate = state.subAdvisorRate === ''
      ? 0
      : parseFloat(state.subAdvisorRate) / 100;
    const parsedSubAdvisorShare = state.subAdvisorShare === ''
      ? 0
      : parseFloat(state.subAdvisorShare) / 100;
    const parsedadvisorRate = state.advisorRate === ''
      ? 0
      : parseFloat(state.advisorRate) / 100;

    const exceededInvestmentAmount = parsedInvestmentAmount + parsedHouseholdAum > 5000000;
    const showCalculations = parsedInvestmentAmount > 0
      && !exceededInvestmentAmount
      && state.modelType !== ""
      && state.advisorAum !== "";

    const investmentManagementRate = parsedSubAdvisorRate * parsedSubAdvisorShare;
    const investmentManagementFee = investmentManagementRate * parsedInvestmentAmount;
    const advisorFee = parsedadvisorRate * parsedInvestmentAmount;
    const feeSchedule = showCalculations
      ? PlatformFeeSchedule(state.modelType, state.advisorAum)
      : null;
    const feeCalculation = showCalculations
      ? PlatformFeeCalculation(feeSchedule, parsedInvestmentAmount, parsedHouseholdAum)
      : null;

    return {
      ...state,
      parsedHouseholdAum: parsedHouseholdAum,
      parsedInvestmentAmount: parsedInvestmentAmount,
      parsedadvisorRate: parsedadvisorRate,
      exceededInvestmentAmount: exceededInvestmentAmount,
      showCalculations: showCalculations,
      investmentManagementRate: investmentManagementRate,
      investmentManagementFee: investmentManagementFee,
      advisorFee: advisorFee,
      feeSchedule: feeSchedule,
      feeCalculation: feeCalculation,
    };
  }

  return {
    getState: getState,
    updateInvestmentAmount: updateInvestmentAmount,
    updateHouseholdAum: updateHouseholdAum,
    updateModelType: updateModelType,
    updateAdvisorAum: updateAdvisorAum,
    updateSubAdvisorShare: updateSubAdvisorShare,
    updateSubAdvisorRate: updateSubAdvisorRate,
    updateAdvisorRate: updateAdvisorRate,
  }
}

export default AppState;
