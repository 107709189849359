import './App.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

function ModelType(props) {
  function onChange(event) {
    props.setVal(event.target.value);
  }

  return (
    <div className="ModelType">
      <Form.Label htmlFor="modelType">Select Model Type</Form.Label>
      <InputGroup className="mb-3">
        <Form.Select id="modelType" value={props.val} onChange={onChange}>
          <option value=""></option>
          <option value="UMA">UMA (multi-sleeve)</option>
          <option value="Fund">Fund (single-sleeve)</option>
        </Form.Select>
      </InputGroup>
      <div className="ModelTypeInstructions">
        The model program is selected at the account level. A client may have accounts in UMA Models and other accounts in Fund Models.
      </div>
    </div>
  );
}

export default ModelType;
