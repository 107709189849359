import './App.css';

function SaveAsPdf(props) {
  function printPage() {
    window.scrollTo({top: 0, left: 0, behavior: 'instant'});
    var clone = document.getElementById('App').cloneNode(true);
    clone.classList.add('printing');
    var opt = {
      filename: 'FeeCalc.pdf',
      image: {type: 'png'},
      html2canvas: {scale: 1},
      jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait'}
    };
    global.html2pdf().set(opt).from(clone).save();
  }
  return (
    <div className="SaveAsPdf hide-while-printing">
      <button onClick={printPage} type="button" className="btn btn-primary">Save as PDF</button>
    </div>
  );
}

export default SaveAsPdf;
