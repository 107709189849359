function FooterDisclaimer() {
  return (
    <div className="FooterDisclaimer">
      <p>
        Advisory services are offered through Freedom Advisors ("Freedom"), a registered investment adviser.
        This is intended for informational and comparison purposes only and is subject to change based on various factors.
        Fees are calculated as a percentage of the average daily balance of the Client's Account in the prior month and are paid monthly in arrears.
        Actual fees charged can be located in the client's Investment Plan and Investment Management Agreement at account opening,
        and monthly performance reports once funded thereafter.
      </p>
      <p>
        The Advisory Fee will not exceed 1.5% of the Account value per annum.
        All Account assets, except for unaffiliated cash, will be included by default in calculating the billable value of the Account for purposes of computing the Advisory Fee.
        Unaffiliated cash is defined as cash held outside of a managed portfolio.
      </p>
      <p>
        The Freedom Platform Fee tiering is based on the combined assets of the Client household and the Financial Advisor's custom fee schedule with Freedom;
        the fee is charged pro rata dependent on the percentage of the household assets within each Freedom Program.
        For purposes of determining fees, a household typically includes all Clients that reside at the same primary residence.
        All assets, except for unaffiliated cash and non-managed holdings, will be included in calculating the billable value for purposes of computing the Freedom Platform Fee.
      </p>
      <p>
        The Freedom Platform Fee rates presented in this calculator do not include any Financial Advisory Firm mark-up that may be applied,
        which would increase the actual fee.
      </p>
      <p>
        The actual Investment Management Fee charged may vary due to changes in the selected Model or Program,
        changes in the composition of strategies within a Model,
        and variations in the value of Account assets affiliated with each model due to normal market fluctuations.
        Freedom does not charge an Investment Management Fee for Freedom Portfolios.
      </p>
    </div>
  );
}

export default FooterDisclaimer;
